import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import Header from "src/components/common/header/Header";
import Button from "src/components/common/button/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { headers } from "src/helper/authToken";
import { HighlightCardData } from "src/components/highlight-card/HighlightCard";
import { convertTime } from "src/helper/convertTime";
import { optionCountry } from "src/helper/optionCountry";
import { formatDate } from "src/helper/formatDate";
import { getSessionKey } from "src/helper/sessionServices";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { useTranslation } from "react-i18next";
function HighlightDetailData() {
  const { loading, setLoading } = useLoader();
  const [highlightDetailData, setHighlightDetail] =
    useState<HighlightCardData>();
  const [highlightDetailFav, setHighlightDetailFav] =
    useState<HighlightCardData>();
  const [highlightDetailInterest, setHighlightDetailInterest] =
    useState<HighlightCardData>();
  const [isFavorite, setIsFavorite] = useState(false);
  const userData = getSessionKey("authToken");
  console.log(getSessionKey("userData"), "Userdata!!!");
  const { i18n, t } = useTranslation(["Highlight"]);
  const lang = getSessionKey("selectedLangCode");
  /* const [highlightDetailData, setHighlightDetail] = useState({
    eventTitle: "Focus on Climate Hedging",
    eventLocation: "Milan, Italy",
    eventDate: "8th Aug, 2024",
    eventTime: "16:00-17:00 CET",
    eventBody: `<p>In the face of escalating climate challenges, businesses are increasingly turning towards climate hedging as a strategic imperative. Acknowledging the risks posed by climate change, companies are implementing innovative solutions to mitigate environmental impact. From renewable energy investments to carbon offset initiatives, the focus on climate hedging not only safeguards against potential risks but also aligns businesses with global sustainability goals. Embracing this forward-looking approach not only future-proofs operations but also contributes to building a more resilient and environmentally conscious business ecosystem, where companies actively play a role in combating climate change for a sustainable future.</p>`,
    eventImage:
      "https://fastly.picsum.photos/id/20/3670/2462.jpg?hmac=CmQ0ln-k5ZqkdtLvVO23LjVAEabZQx2wOaT4pyeG10I",
  }); */
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const highlightId = searchParams.get("highlightcode");
  // const [isAuthToken, setIsAuthToken] = useState(false)
  const getLabelByValue = (value: string) => {
    const item = optionCountry.find((item) => item.label === value);
    return item ? item.label : "Label not found";
  };

  const [favFlagToReload, setFavFlagToReload] = useState<boolean>(true);
  useEffect(() => {
    if (favFlagToReload) {
      setLoading(true);
      // Make a GET request with the specified headers
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/highlight?highlightcode=${highlightId}&lang_id=${
            getSessionKey("userData")?.lang_id
          }`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setHighlightDetail(response?.data?.data[0]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
      setFavFlagToReload(false);
    }
  }, [highlightId, favFlagToReload]);

  const [favouriteId, setFavouriteId] = useState<number | null>(null);
  const [interestedId, setInterestedId] = useState<number | null>(null);
  useEffect(() => {
    let filteredArray: any = highlightDetailData?.HighlightInterestNFavourites
      ? highlightDetailData?.HighlightInterestNFavourites.filter(
          (item) => item.type === "favourite"
        )
      : "";
    let filteredArrayInterested: any =
      highlightDetailData?.HighlightInterestNFavourites
        ? highlightDetailData?.HighlightInterestNFavourites.filter(
            (item) => item.type === "interested"
          )
        : "";
    //const isFavoritePresent = filteredArray.length > 0;
    if (filteredArray.length > 0) {
      setIsFavorite(true);
      setFavouriteId(Number(filteredArray[0].id));
    } else {
      setIsFavorite(false);
      setFavouriteId(null);
    }
    if (filteredArrayInterested.length > 0) {
      setInterestedId(Number(filteredArrayInterested[0].id));
    } else {
      setInterestedId(null);
    }
  }, [highlightDetailData]);

  const itineraryHighlights =
    highlightDetailData?.highligthdetail &&
    highlightDetailData?.highligthdetail.length > 0
      ? highlightDetailData?.highligthdetail.filter(
          (highlight) => highlight.type === "itinerary"
        )
      : [];
  const stepsHighlights =
    highlightDetailData?.highligthdetail &&
    highlightDetailData?.highligthdetail.length > 0
      ? highlightDetailData?.highligthdetail.filter(
          (highlight) => highlight.type === "steps"
        )
      : [];
  //console.log(itineraryHighlights)

  const navigate = useNavigate();
  const handleClick = () => {
    setLoading(true);
    //navigate("/acknowledgement");
    const data = {
      highlightcode: highlightId,
      userid: userData?.id,
      type: "interested",
      redirectionlink:
        highlightDetailData?.eventType === "online"
          ? highlightDetailData?.meeturl
          : "",
      email: userData?.email,
    };
    axios
      .post(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/interestnfovourite`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        //setHighlightDetail(response?.data?.data[0]);
        if (response?.data?.status === "Success") {
          setLoading(false);
          let historyData = {
            reference: "highlight interested",
          };
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const getDownloadObj = {
    highlightid: highlightId,
    userid: userData?.id,
    type: "favourite",
    redirectionlink:
      highlightDetailData?.eventType === "online"
        ? highlightDetailData?.meeturl
        : "",
    email: userData?.email,
  };
  //useEffect(() => {
  const filteredArray = highlightDetailData?.HighlightInterestNFavourites
    ? highlightDetailData?.HighlightInterestNFavourites.filter(
        (item) => item.type === "interested"
      )
    : "";
  const filteredArrayFavourite =
    highlightDetailData?.HighlightInterestNFavourites
      ? highlightDetailData?.HighlightInterestNFavourites.filter(
          (item) => item.type === "favourite"
        )
      : "";
  //const isFavoritePresent = filteredArray.length > 0;
  /* if(filteredArray.length > 0){
      //setIsFavorite(true)
    } */
  // })

  const handleWithdrawInterested = () => {
    setLoading(true);
    //navigate("/acknowledgement");
    axios
      .delete(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/interestnfovourite?id=${interestedId}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response: any) => {
        //setHighlightDetail(response?.data?.data[0]);
        if (response?.data?.status === "Success") {
          // setIsFavorite(!isFavorite);
          // setLoading(false);
          setFavFlagToReload(true);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const handleFavorite = () => {
    setLoading(true);
    if (isFavorite) {
      axios
        .delete(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/interestnfovourite?id=${favouriteId}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response: any) => {
          //setHighlightDetail(response?.data?.data[0]);
          if (response?.data?.status === "Success") {
            // setIsFavorite(!isFavorite);
            // setLoading(false);
            setFavFlagToReload(true);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    } else {
      const payload = {
        highlightcode: highlightId,
        userid: getSessionKey("authToken")?.id,
        type: "favourite",
      };
      axios
        .post(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/interestnfovourite`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response: any) => {
          //setHighlightDetail(response?.data?.data[0]);
          if (response?.data?.status === "Success") {
            setFavFlagToReload(true);
            // setLoading(false);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
    // setIsFavorite(!isFavorite);
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true}></Header>
        {getSessionKey("authToken") && (
          <button className="fav-btn" onClick={handleFavorite}>
            {isFavorite ? (
              <i className="favorite-filled"></i>
            ) : (
              <i className="favorite"></i>
            )}
          </button>
        )}
      </header>
      <main>
        <div className="lyt-main typ-highlight-detail">
          <section>
            <div className="bs-section">
              <div className="sec-cont">
                <div className={`${styles.highlightDetailWrap}`}>
                  <div className={`${styles.imgWrap}`}>
                    <img src={`${highlightDetailData?.imageUrl}`} alt="" />
                  </div>
                  <div className={`${styles.contentWrap}`}>
                    <div className={`${styles.contHead}`}>
                      <h1 className={`${styles.eventTitle}`}>
                        {highlightDetailData?.name}
                      </h1>
                      <span className={`${styles.date}`}>
                        {highlightDetailData?.eventType === "offline" ? (
                          <i className={`${styles.location}`}></i>
                        ) : highlightDetailData?.eventType ===
                          "communication" ? (
                          <i className={`${highlightDetailData?.subtype}`}></i>
                        ) : (
                          <i
                            className={`${highlightDetailData?.eventType}`}
                          ></i>
                        )}
                        {/* {highlightDetailData?.eventtime} */}
                        {highlightDetailData?.eventType === "offline"
                          ? highlightDetailData?.provience +
                            ", " +
                            getLabelByValue(highlightDetailData?.country)
                          : highlightDetailData?.eventType === "communication"
                          ? highlightDetailData?.subtype
                          : highlightDetailData?.eventType}
                      </span>
                      <span className={`${styles.date}`}>
                        <i className={`${styles.calendar}`}></i>
                        {formatDate(highlightDetailData?.eventdate!)}
                      </span>
                      {highlightDetailData?.eventType !== "communication" ? (
                        <span className={`${styles.date}`}>
                          <i className={`${styles.time}`}></i>
                          {/* {highlightDetailData?.eventtime} */}
                          {convertTime(
                            highlightDetailData?.eventtime || "",
                            highlightDetailData?.eventendtime !== "" &&
                              highlightDetailData?.eventendtime
                              ? highlightDetailData?.eventendtime
                              : highlightDetailData?.eventtime || ""
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={`${styles.section}`}>
                      <h3 className={`${styles.secTitle}`}>
                        {t("highlight_detailsAbout")}
                      </h3>
                      <div
                        className="cont-body"
                        dangerouslySetInnerHTML={{
                          __html: highlightDetailData?.about!,
                        }}
                      ></div>
                    </div>
                    {highlightDetailData?.eventType !== "communication" &&
                    itineraryHighlights.length > 0 ? (
                      <div className={`${styles.section}`}>
                        <h2 className={`${styles.secTitle}`}>
                          {t("highlight_detailsItinerary")}
                        </h2>
                        <div className={`${styles.cardWrap}`}>
                          <div className={`${styles.card}`}>
                            <div className={`${styles.stepsList}`}>
                              {/* <div className={`${styles.steps}`}>
                              <p>Commencement address</p>
                            </div>
                            <div className={`${styles.steps}`}>
                              <p>Key note session by Rinaldo Piaggio </p>
                            </div>
                            <div className={`${styles.steps}`}>
                              <p>Circular economy in sustainability</p>
                            </div>
                            <div className={`${styles.steps}`}>
                              <p>Insights into Banyan Nation</p>
                            </div>
                            <div className={`${styles.steps}`}>
                              <p>Closing address</p>
                            </div> */}
                              {itineraryHighlights.map((highlight, index) => (
                                <div className={`${styles.steps}`} key={index}>
                                  <p>{highlight.title}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {highlightDetailData?.eventType !== "communication" ? (
                      <div className={`${styles.section}`}>
                        <h2 className={`${styles.secTitle}`}>
                          {t("highlight_detailsEnsure")}
                        </h2>
                        <div className={`${styles.cardWrap}`}>
                          <div className={`${styles.card}`}>
                            <div className={`${styles.checkList}`}>
                              {/* <div className={`${styles.iconTextWrap}`}>
                              <i className={`${styles.check}`}></i>
                              <span className={`${styles.date}`}>
                                Reach 20 minutes before the event.
                              </span>
                            </div>
                            <div className={`${styles.iconTextWrap}`}>
                              <i className={`${styles.check}`}></i>
                              <span className={`${styles.date}`}>
                                Adhere to the seating chart.
                              </span>
                            </div>
                            <div className={`${styles.iconTextWrap}`}>
                              <i className={`${styles.check}`}></i>
                              <span className={`${styles.date}`}>
                                Mobile phones on silent during the event.
                              </span>
                            </div> */}
                              {stepsHighlights.map((highlight, index) => (
                                <div
                                  className={`${styles.iconTextWrap}`}
                                  key={index}
                                >
                                  <i className={`${styles.check}`}></i>
                                  <span className={`${styles.date}`}>
                                    {highlight.title}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {highlightDetailData?.eventType === "offline" ? (
                      <div className={`${styles.section}`}>
                        <h2 className={`${styles.secTitle}`}>
                          {t("highlight_detailsVenue")}
                        </h2>
                        {/* <div className={`${styles.content}`}>
                        <span className={`${styles.textLine}`}>
                          Hotel Sanpi Milano,
                        </span>
                        <span className={`${styles.textLine}`}>
                          Via Lazzaro Palazzi 18,
                        </span>
                        <span className={`${styles.textLine}`}>
                          Milan, Italy, 20124
                        </span>
                      </div> */}
                        <div
                          className={`${styles.contBody} cont-body`}
                          dangerouslySetInnerHTML={{
                            __html: highlightDetailData?.venue!,
                          }}
                        ></div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className={`${styles.section}`}>
                      <h2 className={`${styles.secTitle}`}>
                        {t("highlight_detailsQuery")}
                      </h2>
                      <div className={`${styles.content}`}>
                        {/* <span className={`${styles.textLine}`}>
                          Write to
                          <a href="" className={`${styles.linkText}`}>
                            eventi@asiaimpact.com
                          </a>
                        </span> */}
                        {highlightDetailData?.querydetail}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {highlightDetailData?.eventType !== "communication" ? (
            <>
              {!(filteredArray.length > 0) && getSessionKey("authToken") ? (
                <div className="act-foot">
                  <Button btnStyle="primary" onClick={handleClick}>
                    {t("highlight_interestedBtn")}
                  </Button>
                </div>
              ) : (
                <div className="act-foot">
                  <Button btnStyle="primary" onClick={handleWithdrawInterested}>
                    {t("highlight_withdrawBtn")}
                  </Button>
                </div>
              )}
            </>
          ) : null}
        </div>
      </main>
    </>
  );
}

export default HighlightDetailData;
