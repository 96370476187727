import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import ReportsCard, {
  ReportCardData,
} from "src/components/reports-card/ReportsCard";
import Header from "src/components/common/header/Header";
import axios from "axios";
import { authToken, headers } from "src/helper/authToken";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { getSessionKey } from "src/helper/sessionServices";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { Tab, Tabs } from "@mui/material";
import CheckBox from "src/components/common/checkbox/CheckBox";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

const filterTabItems: { [key: string]: any[] } = {
  en: [
    {
      id: "tab01",
      title: "Report",
      isActive: false,
      tabPanelItems: [
        {
          itemType: "checkbox",
          //itemId: "annualReports",
          itemId: "Annual",
          label: "Annual Reports",
          count: 15,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          //itemId: "quarterlyReports",
          itemId: "Quarterly",
          label: "Quarterly Reports",
          count: 12,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          //itemId: "companyReports",
          itemId: "Company",
          label: "Company Reports",
          count: 5,
          isChecked: false,
        },
      ],
    },
    {
      id: "tab02",
      title: "Company",
      isActive: false,
      tabPanelItems: [
        {
          itemType: "checkbox",
          itemId: "Banyan Nation",
          label: "Banyan Nation",
          count: 3,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "vilcart",
          label: "Vilcart",
          count: 2,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "creditAccessIndia",
          label: "Credit Access India",
          count: 5,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "onePuhunan",
          label: "One Puhunan",
          count: 3,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "creditas",
          label: "Creditas",
          count: 1,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "machineMaze",
          label: "Machine Maze",
          count: 2,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "arya",
          label: "Arya",
          count: 4,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "binaArtha",
          label: "Bina Artha",
          count: 2,
          isChecked: false,
        },
      ],
    },
    {
      id: "tab03",
      title: "Date",
      isActive: false,
      tabPanelItems: [
        {
          itemType: "radio",
          itemId: "90",
          label: "Last 3 Months",
          count: 0,
          isChecked: false,
          value: "90",
        },
        {
          itemType: "radio",
          itemId: "120",
          label: "Last 6 months",
          count: 0,
          isChecked: false,
          value: "120",
        },
        {
          itemType: "radio",
          itemId: "365",
          label: "Last 1 year",
          count: 0,
          isChecked: false,
          value: "365",
        },
        {
          itemType: "radio",
          itemId: "730",
          label: "Last 2 years",
          count: 0,
          isChecked: false,
          value: "730",
        },
        {
          itemType: "radio",
          itemId: "all",
          label: "All",
          count: 0,
          isChecked: false,
          value: "",
        },
      ],
    },
  ],
  it: [
    {
      id: "tab01",
      title: "Report",
      isActive: false,
      tabPanelItems: [
        {
          itemType: "checkbox",
          //itemId: "annualReports",
          itemId: "Annual",
          label: "Report annuali",
          count: 15,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          //itemId: "quarterlyReports",
          itemId: "Quarterly",
          label: "Report trimestrali",
          count: 12,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          //itemId: "companyReports",
          itemId: "Company",
          label: "Report società",
          count: 5,
          isChecked: false,
        },
      ],
    },
    {
      id: "tab02",
      title: "Società",
      isActive: false,
      tabPanelItems: [
        {
          itemType: "checkbox",
          itemId: "Banyan Nation",
          label: "Banyan Nation",
          count: 3,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "vilcart",
          label: "Vilcart",
          count: 2,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "creditAccessIndia",
          label: "Credit Access India",
          count: 5,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "onePuhunan",
          label: "One Puhunan",
          count: 3,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "creditas",
          label: "Creditas",
          count: 1,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "machineMaze",
          label: "Machine Maze",
          count: 2,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "arya",
          label: "Arya",
          count: 4,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "binaArtha",
          label: "Bina Artha",
          count: 2,
          isChecked: false,
        },
      ],
    },
    {
      id: "tab03",
      title: "Data",
      isActive: false,
      tabPanelItems: [
        {
          itemType: "radio",
          itemId: "90",
          label: "Ultimi 3 mesi",
          count: 0,
          isChecked: false,
          value: "90",
        },
        {
          itemType: "radio",
          itemId: "120",
          label: "Ultimi 6 mesi",
          count: 0,
          isChecked: false,
          value: "120",
        },
        {
          itemType: "radio",
          itemId: "365",
          label: "Ultimo anno",
          count: 0,
          isChecked: false,
          value: "365",
        },
        {
          itemType: "radio",
          itemId: "730",
          label: "Ultimi 2 anni",
          count: 0,
          isChecked: false,
          value: "730",
        },
        {
          itemType: "radio",
          itemId: "all",
          label: "Tutti",
          count: 0,
          isChecked: false,
          value: "",
        },
      ],
    },
  ],
};

function ReportsListing() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Highlight"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const [reportsData, setReportsData] = useState<ReportCardData[]>([]);
  const [reportsSearchData, setReportsSearchData] = useState<ReportCardData[]>(
    []
  );
  const [triggerGetReports, setTriggerGetReports] = useState<boolean>(true);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  useEffect(() => {
    if (triggerGetReports) {
      setLoading(true);
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/report?lang_id=${
            getSessionKey("userData")?.lang_id
          }`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          setReportsData(response?.data?.data);
          setReportsSearchData(response?.data?.data);
          setLoading(false);
          setSelectedFilter([]);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
      setTriggerGetReports(false);
    }
  }, [triggerGetReports]);
  /* const [reportsData, setReportsData] = useState<ReportCardData[]>([
    {
      reportId: "001",
      filename: "Banyan Nation",
      tenure: "2022-23",
      reporttype: "Annual Report",
      tag: "Latest",
      addhome: true
    },
    {
      reportId: "002",
      filename: "Vilcart Tech.",
      tenure: "2022-23",
      reporttype: "Annual Report",
      tag: "Trending",
      addhome: true
    },
    {
      reportId: "001",
      filename: "One Puhunan",
      tenure: "2022-23",
      reporttype: "Annual Report",
      tag: "Highlight",
      addhome: true
    },
    {
      reportId: "001",
      filename: "Credit Access India",
      tenure: "2022-23",
      reporttype: "Annual Report",
      tag: "In Focus",
      addhome: true
    },
  ]); */

  const isWithinLast30Days = (date: any, count: any) => {
    const currentDate: any = new Date();
    const givenDate: any = new Date(date);
    const diffTime = Math.abs(currentDate - givenDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= parseInt(count);
  };

  const [selectedFilter, setSelectedFilter] = useState([""]);
  const handleSelectFilter = () => {
    //setSelectedFilter(city);
    let tempHighArr = [];
    tempHighArr = selectedFilter
      ? //? reportsData.filter((item) => ((item.provience).includes(selectedFilter) || item.subtype == selectedFilter || item.eventType == selectedFilter))
        reportsData.filter(
          (item) =>
            selectedFilter.includes(item.reporttype) ||
            selectedFilter.includes(item.Company.name) ||
            (selectedFilter.includes("90") ||
            selectedFilter.includes("120") ||
            selectedFilter.includes("365") ||
            selectedFilter.includes("730")
              ? isWithinLast30Days(item.reportdate, selectedFilter)
              : null)
        )
      : reportsData;
    setReportsSearchData(tempHighArr);
    setOpenFilterDrawer(false);
  };

  const handleClearAll = () => {
    setSelectedFilter([]);
    setReportsSearchData(reportsData);
    setOpenFilterDrawer(false);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    //setSelectedFilter(newValue);
  };

  const handleFilterChange = (value: any, name: any) => {
    //console.log('filterValue', value)
    if (value.checked) {
      if (name === "radio") {
        let filteredArray = selectedFilter.filter(
          (item) => !["90", "120", "365", "730", ""].includes(item)
        );
        setSelectedFilter(filteredArray);
        setSelectedFilter((prevArray) => [...prevArray, value.value]);
      } else {
        setSelectedFilter((prevArray) => [...prevArray, name]);
      }
    } else {
      let filteredArray = selectedFilter.filter((item) => item !== name);
      setSelectedFilter(filteredArray);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <header>
        <Header backLink={true} pageTitle="Reports"></Header>
        <button
          className="filter-btn"
          type="button"
          onClick={() => setOpenFilterDrawer(true)}
        >
          <i></i>
          {t("highlight_filterBtn")}
        </button>
      </header>
      <main>
        <div className="lyt-main typ-main">
          <section>
            <div className="bs-section typ-depth">
              <div className="sec-cont">
                <div className={`${styles.reportsList}`}>
                  {reportsSearchData.map((item, index) => {
                    return (
                      <div key={index} className={`${styles.item}`}>
                        <ReportsCard
                          {...item}
                          bigCard={true}
                          setTriggerGetReports={setTriggerGetReports}
                        ></ReportsCard>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <BottomSheet
        title={t("highlight_filterBtn")}
        primaryBtnText={t("highlight_applyBtn")}
        secondaryBtnText={t("highlight_clearAllBtn")}
        size="large"
        open={openFilterDrawer}
        setOpen={setOpenFilterDrawer}
        btnOnClick={handleSelectFilter}
        btnSecondaryOnClick={handleClearAll}
      >
        <div className="bs-filter">
          <div className="left-wrap">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              {filterTabItems[i18n.language].map((item: any, index: number) => {
                return <Tab key={index} label={item.title} />;
              })}
            </Tabs>
          </div>
          <div className="right-wrap">
            {filterTabItems[i18n.language].map((item: any, index: number) => {
              return (
                <div
                  className="tabpanel"
                  role="tabpanel"
                  hidden={value !== index}
                  key={index}
                >
                  <div className="panel-list">
                    {item.tabPanelItems.map((item: any, index: number) => {
                      return (
                        <div className="filter-item" key={index}>
                          {item.itemType === "radio" ? (
                            <>
                              <div className="radio-group">
                                <input
                                  type="radio"
                                  id={item.itemId}
                                  name={item.itemType}
                                  value={item.itemId}
                                  onChange={(e: any) => {
                                    handleFilterChange(e.target, e.target.name);
                                  }}
                                  checked={selectedFilter.includes(item.itemId)}
                                />
                                <label htmlFor={item.itemId}>
                                  {item.label}
                                </label>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {item.itemType === "checkbox" ? (
                            <>
                              <CheckBox
                                id={item.itemId}
                                label={item.label}
                                name={item.itemId}
                                value={item.itemId}
                                onChange={(e: any) => {
                                  handleFilterChange(e.target, e.target.name);
                                }}
                                isChecked={selectedFilter.includes(item.itemId)}
                              ></CheckBox>
                            </>
                          ) : (
                            ""
                          )}
                          {/* {item.count !== 0 ? (
                            <span className="count">{item.count}</span>
                          ) : (
                            <></>
                          )} */}
                        </div>
                      );
                    })}
                    `
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </BottomSheet>
    </>
  );
}

export default ReportsListing;
