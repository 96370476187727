import React, { useEffect, useState } from "react";
import { Tab, Tabs, FormControl, TextField } from "@mui/material";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import styles from "./style.module.scss";
import CheckBox from "../checkbox/CheckBox";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { headers } from "src/helper/authToken";
import {
  clearSession,
  getSessionKey,
  logoutSession,
  setSessionKey,
} from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useLoader } from "src/context/LoaderContext";
import { connectSocket } from "src/helper/socketHelper";
export interface HeaderProps {
  showProfile?: boolean;
  pageTitle?: string;
  backLink?: boolean;
  actionItems?: string[];
  showChat?: boolean;
  showProgress?: boolean;
  currentStep?: number;
  totalSteps?: number;
  center?: boolean;
  detailData?: any;
  isFavouriteFlag?: boolean;
  clientData?: {
    id?: any;
    companyid?: any;
    activerequestid?: any;
    activechatrequestid?: any;
    activechatrequest?: {
      request_status?: any;
      chat_closed_on?: any;
      chat_closed_by?: any;
    };
    Sender_Detail?: {
      id?: any;
      userdetail?: {
        firstname?: any;
        lastname?: any;
      };
    };
    Receiver_Detail?: {
      email?: any;
      id?: any;
    };
  };
  deleteRequestDataId?: any;
  filterFlag?: boolean;
}

function Header(props: HeaderProps) {
  const { i18n, t } = useTranslation(["Header"]);
  const { userData, setUserData } = useLoader();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openDownloadDrawer, setOpenDownloadDrawer] = useState(false);
  const [openDeleteDrawer, setOpenDeleteDrawer] = useState(false);
  const [openDeleteRequestDrawer, setOpenDeleteRequestDrawer] = useState(false);
  const [openEndDrawer, setOpenEndDrawer] = useState(false);
  const [openReportDrawer, setOpenReportDrawer] = useState(false);
  const [isFavorite, setIsFavorite] = useState(
    props.isFavouriteFlag ? props.isFavouriteFlag : false
  );
  const [menuActive, setMenuActive] = useState(false);
  const [value, setValue] = React.useState(0);
  const [tempProfileImage, setTempProfileImage] = useState("");
  const [openPreviewDrawer, setOpenPreviewDrawer] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState({
    id: "",
    name: "",
    size: "",
    type: "",
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openFilePreviewDrawer, setOpenFilePreviewDrawer] = useState(false);
  const [previewURL, setPreviewURL] = useState<string | undefined>("");
  const [previewFileObj, setPreviewFileObj] = useState<Blob>({} as Blob);
  const backLink = useNavigate();
  const navigate = useNavigate();
  const handleNotification = () => {
    backLink("/notifications");
  };

  const handleFavorite = () => {
    axios
      .post(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/interestnfovourite`,
        props?.detailData,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        //setHighlightDetail(response?.data?.data[0]);
        if (response?.data?.status === "Success") {
          setIsFavorite(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setMenuActive(!menuActive);
  };

  const handleUpload = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      // Check file size
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage(`${t("header_errorFileSize")}`);
        return;
      }
      // Check file type
      if (!file.type.includes("image") && !file.type.includes("pdf")) {
        setErrorMessage(`${t("header_errorUploadFile")}`);
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "type",
        file.type.includes("pdf") ? "document" : "images"
      );
      axios
        .post(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/documentUpload",
          formData,
          {
            headers: {
              Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            setErrorMessage(null);
            setUploadedFile({
              id: "" + e.target.files[0].lastModified,
              name: e.target.files[0].name,
              size: "" + Math.round(e.target.files[0].size / 1024),
              type: e.target.files[0].type,
            });
            setPreviewFileObj(e.target.files[0]);

            // harcoded pdf file url remove after storage logic integarted
            // res.data.data.fileurl = e.target.files[0].type === "application/pdf" ? "https://s3.amazonaws.com/awsdocs/S3/latest/s3-gsg.pdf" : "https://ucarecdn.com/b223131b-a0a0-4812-9805-0029fe27c169/banyan_logo.png";
            /* if (props.getFileData)
              props.getFileData(e.target.files[0], res.data.data, props?.index); */
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  };

  const filePreview = () => {
    console.log("previewURL", previewURL);
    setOpenFilePreviewDrawer(true);
  };

  const handleConfirmUpload = () => {
    setOpenPreviewDrawer(false);
  };

  const handleCancelPreview = () => {
    setUploadedFile({
      id: "",
      name: "",
      size: "",
      type: "",
    });
    setDeleteFlag(true);
    setPreviewURL(""); /*
      if (props.getFileData)
        props.getFileData("", null, props?.index); */
  };

  const filterTabItems = [
    {
      id: "tab01",
      title: "Category",
      isActive: false,
      tabPanelItems: [
        {
          itemType: "checkbox",
          itemId: "onlineEvent",
          label: "Online Event",
          count: 15,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "offlineEvent",
          label: "Offline Event",
          count: 12,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "reads",
          label: "Reads",
          count: 5,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "important",
          label: "Important",
          count: 1,
          isChecked: false,
        },
        {
          itemType: "checkbox",
          itemId: "reserach",
          label: "Research",
          count: 2,
          isChecked: false,
        },
      ],
    },
    {
      id: "tab03",
      title: "Date",
      isActive: false,
      tabPanelItems: [
        {
          itemType: "radio",
          itemId: "last1month",
          label: "Last 1 Month",
          count: 0,
          isChecked: false,
        },
        {
          itemType: "radio",
          itemId: "last3months",
          label: "Last 3 Months",
          count: 0,
          isChecked: false,
        },
        {
          itemType: "radio",
          itemId: "all",
          label: "All",
          count: 0,
          isChecked: false,
        },
      ],
    },
  ];

  const [userDetails, setUserDetails] = useState<any>(null);
  const refreshToken = getSessionKey("authToken")?.refreshtoken;
  let authtoken = getSessionKey("authToken");
  const [inputValue, setInputValue] = useState("");
  const [notiCount, setNotiCount] = useState<any>(0);
  useEffect(() => {
    if (authtoken?.accesstoken && authtoken?.email && authtoken?.id) {
      let socObj = connectSocket(
        authtoken?.accesstoken,
        authtoken?.email,
        authtoken?.id,
        navigate
      );
      socObj.emit("get_notifications_count", {
        email: authtoken?.email,
        id: authtoken?.id,
      });
      socObj.on("notification_count", (x) => {
        console.log("notification_count", x);
        setNotiCount(x);
      });
    }
    if (
      getSessionKey("userRole") !== "explorer" &&
      window.location.pathname === "/impact" &&
      getSessionKey("authToken") !== null
    ) {
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user?id=${
            getSessionKey("authToken")?.id
          }`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            // console.log('response.data.data', response.data.data[0])
            // console.log('firstName', response.data.data[0]?.userdetail?.firstname)
            if (
              (userData?.pin === null || response.data.data[0]?.pin === null) &&
              (userData?.isSetPinSkip === undefined || !userData?.isSetPinSkip)
            )
              response.data.data[0]["isSetPinSkip"] = false;
            else response.data.data[0]["isSetPinSkip"] = true;

            setSessionKey("userData", response.data.data[0]);
            setSessionKey("userExistData", response.data.data);
            setUserDetails(response.data.data[0]);
            setUserData(response.data.data[0]);
            console.log("after value added", response.data.data[0]);

            // socket configuration for notification count
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    } else if (getSessionKey("userData") !== null) {
      setUserDetails(getSessionKey("userData"));
    }
  }, []);

  useEffect(() => {
    if (props.currentStep && props.totalSteps) {
      document.documentElement.style.setProperty(
        "--progress-count",
        (props.currentStep * 100) / props.totalSteps + "%"
      );
    }
  }, [props.currentStep]);
  /* useEffect(() => {
    if (props.isFavouriteFlag) {
      setIsFavorite(props.isFavouriteFlag)
    }
  }, [props.isFavouriteFlag]);
 */

  const handleDownload = async () => {
    if (
      typeof window !== "undefined" &&
      window.ReactNativeWebView === undefined
    ) {
      // Web environment
      try {
        const response = await fetch(props?.detailData?.fileurl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = props?.detailData?.filename; // Set the filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("There was a problem with the document download:", error);
      }
    } else {
      // React Native environment
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          url: props?.detailData?.fileurl,
          filename: props?.detailData?.filename,
          action: "download",
        })
      );
    }

    // try {
    //   const response = await fetch(props?.detailData?.fileurl);
    //   if (!response.ok) {
    //     throw new Error("Network response was not ok");
    //   }
    //   const blob = await response.blob();
    //   const url = window.URL.createObjectURL(blob);

    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.download = props?.detailData?.filename; // Set the filename here
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);

    //   window.URL.revokeObjectURL(url);
    // } catch (error) {
    //   console.error("There was a problem with the document download:", error);
    // }
  };

  const shareNews = () => {
    const urlToShare = props?.detailData?.link; // The URL to share
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "share", url: urlToShare })
      );
    } else {
      const url = props?.detailData?.link; // Replace this with your desired URL
      window.open(url, "_blank");
    }
  };

  const handleRedirectProfileSetting = () => {
    navigate("/profile-settings");
  };
  const handleSocket = () => {
    navigate("/advisor-client-relation");
  };
  const handleRemoveRelation = () => {
    axios
      .delete(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/relation?id=${props?.detailData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) navigate("/impact");
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const handleEndChat = (state: string) => {
    //const activeChatRequestData = getSessionKey("activeChatRequestData")
    //console.log("clientData-->", props.clientData)
    const payload = {
      id: props.clientData?.id,
      companyid: props.clientData?.companyid,
      activerequestid: props.clientData?.activerequestid,
      activechatrequestid: props.clientData?.activechatrequestid,
      // location.state.reference === "notification"
      //   ? clientData?.activechatrequestid
      //   : clientData?.activerequestchathistory[0]?.activechatrequestid,
      activechatrequest: {
        request_status: "closed", //pending,accepted,rejected,closed
        chat_closed_on: new Date(), // chat close data
        chat_closed_by:
          getSessionKey("userRole") === "advisor"
            ? getSessionKey("selectedClient")?.investor?.id
            : getSessionKey("authToken")?.id,
      },
    };
    axios
      .put(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequesthistory",
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let historyData = {
            reference: "end chat",
          };
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const handleReportUser = () => {
    /* const userData1 = getSessionKey("userData")
    console.log("userData==>", userData1)
    console.log("clientData==>", props.clientData) */
    const payload = {
      name:
        props?.clientData?.Sender_Detail?.userdetail?.firstname +
        " " +
        props?.clientData?.Sender_Detail?.userdetail?.lastname,
      email: props?.clientData?.Receiver_Detail?.email,
      userid: props?.clientData?.Receiver_Detail?.id,
      complaint_against_user_id: props?.clientData?.Sender_Detail?.id,
      description: inputValue,
      img: uploadedFile,
    };
    axios
      .post(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/complaint",
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let historyData = {
            reference: "complaint raised",
          };
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    // if (value.length <= maxLength) {
    setInputValue(value);
    //}
  };

  const handleDeleteRequestChat = () => {
    axios
      .delete(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activerequest?id=${props?.deleteRequestDataId}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let historyData = {
            reference: "delete request",
          };
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  return (
    <>
      <div className={`bs-header ${props.center ? "center" : ""}`}>
        {props.showProfile ? (
          <div className="profile-wrap" onClick={handleRedirectProfileSetting}>
            {getSessionKey("userData")?.userdetail?.img ? (
              <img
                src={getSessionKey("userData")?.userdetail?.img}
                alt="Client Logo"
              />
            ) : (
              <i className="user-logo"></i>
            )}

            <span className="intro-text">
              {t("header_hiText")}
              {`${
                userDetails === null
                  ? ""
                  : getSessionKey("userRole") === "legalrepresent"
                  ? ", " +
                    userData?.userdetail?.userlrdetail[
                      userData?.userdetail?.userlrdetail.length - 1
                    ]?.companyname
                  : ", " + userDetails?.userdetail?.firstname
              }`}
            </span>
          </div>
        ) : (
          <div className="icon-title-wrap">
            {props.backLink ? (
              <button
                onClick={() => {
                  window.location.pathname === "/chat-details"
                    ? backLink("/chat")
                    : backLink(-1);
                }}
              >
                <i className="back-icon"></i>
              </button>
            ) : (
              <></>
            )}
            {props.pageTitle ? (
              <h1 className="pg-title">{props.pageTitle}</h1>
            ) : (
              <></>
            )}
          </div>
        )}
        {props.showChat &&
        getSessionKey("userRole") !== "explorer" &&
        getSessionKey("authToken") !== null ? (
          <button className="advisor-chat" onClick={handleSocket}>
            {getSessionKey("userRole") === "advisor"
              ? `${t("header_clientText")}`
              : `${t("header_advisorText")}`}
            <i></i>
          </button>
        ) : (
          <></>
        )}
        {props.actionItems &&
        getSessionKey("userRole") !== "explorer" &&
        getSessionKey("authToken") !== null ? (
          <div className="act-wrap">
            {props.actionItems.map((item, index) => {
              switch (item) {
                case "notification":
                  return (
                    <button
                      key={index}
                      className="notification"
                      onClick={handleNotification}
                    >
                      <i></i>
                      <span
                        className="notification-count"
                        style={{ display: notiCount > 0 ? "flex" : "none" }}
                      >
                        {notiCount}
                      </span>
                    </button>
                  );
                case "share":
                  return (
                    <button key={index} className="share" onClick={shareNews}>
                      <i></i>
                    </button>
                  );
                // case "favorite":
                //   return (
                //     <button
                //       key={index}
                //       className="fav-btn"
                //       onClick={handleFavorite}
                //     >
                //       {isFavorite ? (
                //         <i className="favorite-filled"></i>
                //       ) : (
                //         <i className="favorite"></i>
                //       )}
                //     </button>
                //   );
                case "filter":
                  return (
                    <button
                      key={index}
                      className={`filter-btn ${
                        props.filterFlag ? "filter-applied" : ""
                      }`}
                      onClick={() => setOpenFilterDrawer(true)}
                    >
                      <i></i>
                      {t("header_filterText")}
                    </button>
                  );
                case "delete":
                  return (
                    <button
                      key={index}
                      className="delete"
                      onClick={() => setOpenDeleteDrawer(true)}
                    >
                      <i></i>
                    </button>
                  );
                case "download":
                  return (
                    <button
                      key={index}
                      className="download"
                      onClick={() => setOpenDownloadDrawer(true)}
                    >
                      <i></i>
                    </button>
                  );
                case "more":
                  return (
                    <button key={index} className="more" onClick={handleClick}>
                      <i></i>
                    </button>
                  );
                case "deleteRequest":
                  return (
                    <button
                      key={index}
                      className="delete"
                      onClick={() => setOpenDeleteRequestDrawer(true)}
                    >
                      <i></i>
                    </button>
                  );
                default:
                  return <></>;
              }
            })}
          </div>
        ) : (
          <></>
        )}
        {props.showProgress && !getSessionKey("settingType") ? (
          <div className="progress-wrap">
            <span className="step">
              {props.currentStep}/{props.totalSteps}
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
      <BottomSheet
        title={props?.detailData?.PrimaryText}
        subText={props?.detailData?.CompanyName}
        primaryBtnText={props?.detailData?.PrimaryText}
        size="small"
        open={openDownloadDrawer}
        btnOnClick={handleDownload}
        setOpen={setOpenDownloadDrawer}
      >
        {/* <Link to="" download={true}> */}
        <div className="download-wrap">
          <div className="cm-document-wrap">
            <i className="document-icon document"></i>
            <div className="info-wrap">
              {/* <span className="doc-title">Annual Report (2022-23)</span>
                <span className="foot-note">399 KB</span> */}
              <span className="doc-title">
                {props?.detailData?.filename} {props?.detailData?.tenure}
              </span>
            </div>
          </div>
        </div>
        {/* </Link> */}
      </BottomSheet>
      <BottomSheet
        title={t("header_removeDrawerTitle")}
        subText={t("header_removeDrawerSubTitle")}
        primaryBtnText={t("header_removeDrawerBtn")}
        size="medium"
        open={openDeleteDrawer}
        setOpen={setOpenDeleteDrawer}
        btnOnClick={handleRemoveRelation}
      >
        <div className="bs-profile-card">
          <div className="client-profile">
            <div className="logo-wrap">
              <img
                src={props?.detailData?.advisor?.userdetail?.img}
                alt="Client Logo"
              />
            </div>
            <div className="title-wrap">
              <strong className="title-text">
                {props?.detailData?.advisor?.userdetail?.firstname}{" "}
                {props?.detailData?.advisor?.userdetail?.lastname}
              </strong>
              <p className="mail-text">{props?.detailData?.advisor?.email}</p>
            </div>
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        title={t("header_endChatBtn")}
        primaryBtnText={t("header_endBtn")}
        size="large"
        center={true}
        open={openEndDrawer}
        setOpen={setOpenEndDrawer}
        btnOnClick={handleEndChat}
      >
        <div className={`${styles.rejectDrawer}`}>
          <div className={`${styles.rejectWrap}`}>
            <span className={`${styles.remove}`}></span>
            <p className={`${styles.content}`}>{t("header_endChatMessage")}</p>
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        title={t("header_reportUserBtn")}
        primaryBtnText={t("header_submitBtn")}
        size="large"
        open={openReportDrawer}
        setOpen={setOpenReportDrawer}
        btnOnClick={handleReportUser}
      >
        <div className="bs-section typ-chat">
          <div className="bs-form">
            <p className="form-desc">{t("header_reportUserMessage")}</p>
            <form>
              <div className="form-body">
                <div className="form-group">
                  <FormControl variant="standard">
                    <TextField
                      id="reportMessage"
                      maxRows={6}
                      multiline
                      name="report Message"
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={`${styles.uploadCont}`}>
          <div className={`${styles.head}`}>
            <span className={`${styles.title}`}>
              {t("header_uploadReportDocument")}
            </span>
            <span className={`${styles.optional}`}>
              {t("header_optionalTitle")}
            </span>
          </div>
          <div className="bs-upload">
            <div className="doc-card">
              {uploadedFile.id === "" ? (
                <div className="upload-section">
                  <div className="upload-item upload-file">
                    <span className="text">{t("header_uploadTitle")}</span>
                    <input
                      type="file"
                      name=""
                      id="upload-doc"
                      onChange={handleUpload}
                    />
                  </div>
                </div>
              ) : (
                <div className="uploaded-state">
                  <div className="cm-document-wrap">
                    <i
                      className={`document-icon ${
                        uploadedFile.type.indexOf("image") !== -1
                          ? "image"
                          : "document"
                      }`}
                    ></i>
                    <div className="info-wrap">
                      <span className="doc-title">{uploadedFile.name}</span>
                      <span className="foot-note">{uploadedFile.size} KB</span>
                    </div>
                    <div className="action-wrap">
                      <button
                        type="button"
                        className="show-media"
                        onClick={() => filePreview}
                      >
                        <i></i>
                      </button>
                      <button
                        type="button"
                        className="trash-btn"
                        onClick={handleCancelPreview}
                      >
                        <i></i>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="upload-note">
                <strong className="note-title">
                  <i></i>
                  {t("header_noteTitle")}
                </strong>
                <ul className="note-list">
                  <li className="note-item">{t("header_maxFileSize")}</li>
                </ul>
              </div>
            </div>
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        open={openPreviewDrawer}
        primaryBtnText={t("header_confirmBtn")}
        btnDisabled={false}
        size="large"
        title={uploadedFile.name}
        setOpen={setOpenPreviewDrawer}
        btnOnClick={handleConfirmUpload}
        btnSecondaryOnClick={handleCancelPreview}
      >
        <div className="upload-preview">
          {uploadedFile.type.indexOf("image") !== -1 ? (
            <img src={tempProfileImage} alt="" />
          ) : (
            <iframe src={tempProfileImage}></iframe>
          )}
        </div>
      </BottomSheet>
      <BottomSheet
        title={t("chat_requestDetailsDrawerTitle")}
        subText={t("chat_requestDetailsDrawerSubTitle")}
        primaryBtnText={t("chat_requestDetailsDrawerDeleteBtn")}
        size="medium"
        open={openDeleteRequestDrawer}
        setOpen={setOpenDeleteRequestDrawer}
        btnOnClick={handleDeleteRequestChat}
      >
        <div className={`${styles.contentWrap}`}>
          <p className={`${styles.desc}`}>
            {t("chat_requestDetailsDrawerMessage")}
          </p>
        </div>
      </BottomSheet>
      <div
        className={`${styles.menuCont} ${menuActive ? `${styles.show}` : ""}`}
      >
        <ul className={`${styles.list}`}>
          <li className={`${styles.item}`}>
            <button onClick={() => setOpenEndDrawer(true)}>
              {t("header_endChatBtn")}
            </button>
          </li>
          <li className={`${styles.item}`}>
            <button onClick={() => setOpenReportDrawer(true)}>
              {t("header_reportUserBtn")}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Header;
