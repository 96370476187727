import HighlightCard, {
  HighlightCardData,
} from "src/components/highlight-card/HighlightCard";
import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import Header from "src/components/common/header/Header";
import axios from "axios";
import { authToken, headers } from "../../helper/authToken";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { getSessionKey } from "src/helper/sessionServices";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import CheckBox from "src/components/common/checkbox/CheckBox";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

let filterTabItems: any[] = [];

type FilterDataType = {
  Category: string[];
  Date: string[];
  Location: any[];
};

function HighlightListing() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Highlight"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const [highlightData, setHighlightData] = useState<HighlightCardData[]>([]);
  const [highlightSearchData, setHighlightSearchData] = useState<
    HighlightCardData[]
  >([]);
  const [triggerGetHighlight, setTriggerGetHighlight] = useState<boolean>(true);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [provienceData, setProvienceData] = useState<string[]>([]);
  const [locationFilter, setLocationfilter] = useState<any>([]);
  const [regionList, setRegionList] = useState<any>([]);
  const [provinceList, setProvinceList] = useState<any>([]);
  const [locationCountry, setLocationCountry] = useState("");
  const [locationRegion, setLocationRegion] = useState("");
  const [locationProvince, setLocationProvince] = useState("");
  const [filterAppliedFlag, setFilterAppliedFlag] = useState(false);

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    axios
      .get("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/country", {
        headers: {
          Authorization: `Bearer ${
            getSessionKey("authToken") !== null
              ? getSessionKey("authToken").accesstoken
              : ""
          }`,
        },
      })
      .then((response) => {
        let temp = response.data.data.sort((a: any, b: any) =>
          a.countryname.localeCompare(b.countryname)
        );
        setLocationfilter(temp);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);

  useEffect(() => {
    // if (provienceData.length > 0) {
    let jsonArray = provienceData.map((item) => {
      return {
        itemType: "checkbox",
        itemId: item,
        label: item,
        count: 0,
        isChecked: false,
        value: item,
      };
    });

    filterTabItems = [
      {
        id: "tab01",
        title: "Category",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "checkbox",
            itemId: "online",
            label: "Online Event",
            count: 15,
            isChecked: false,
            value: "online",
          },
          {
            itemType: "checkbox",
            itemId: "offline",
            label: "Offline Event",
            count: 12,
            isChecked: false,
            value: "offline",
          },
          {
            itemType: "checkbox",
            itemId: "communication",
            label: "Communication",
            count: 12,
            isChecked: false,
            value: "communication",
          },
          {
            itemType: "checkbox",
            itemId: "Read",
            label: "Reads",
            count: 5,
            isChecked: false,
            value: "Read",
          },
          {
            itemType: "checkbox",
            itemId: "important",
            label: "Important",
            count: 1,
            isChecked: false,
            value: "important",
          },
          {
            itemType: "checkbox",
            itemId: "Research",
            label: "Research",
            count: 2,
            isChecked: false,
            value: "Research",
          },
        ],
      },
      {
        id: "tab03",
        title: "Date",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "radio",
            itemId: "30",
            label: "Last 1 Month",
            count: 0,
            isChecked: false,
            value: "30",
          },
          {
            itemType: "radio",
            itemId: "90",
            label: "Last 3 Months",
            count: 0,
            isChecked: false,
            value: "90",
          },
          {
            itemType: "radio",
            itemId: "",
            label: "All",
            count: 0,
            isChecked: false,
            value: "",
          },
        ],
      },
      {
        id: "tab04",
        title: "Location",
        isActive: false,
        tabPanelItems: [
          {
            itemType: "location",
            // itemId: "30",
            // label: "Last 1 Month",
            // count: 0,
            // isChecked: false,
            // value: "30",
          },
        ],
      },
    ];
    // }
  }, []);

  useEffect(() => {
    if (triggerGetHighlight) {
      setLoading(true);
      // Make a GET request with the specified headers
      axios
        .get(
          `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/highlight?lang_id=${
            getSessionKey("userData")?.lang_id
          }`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          setHighlightData(response?.data?.data);
          setHighlightSearchData(response?.data?.data);
          setLoading(false);
          setSelectedFilter({
            Category: [],
            Date: [],
            Location: [],
          });
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });

      setTriggerGetHighlight(false);
    }
  }, [triggerGetHighlight]);

  useEffect(() => {
    // setHighlightSearchData(highlightData);
    setSearchTerm("");
  }, [highlightData]);

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (searchTerm.length >= 3) {
      let tempHighArr = [];
      tempHighArr = highlightSearchData.filter((item) => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setHighlightSearchData(tempHighArr);
    } else {
      setHighlightSearchData(highlightData);
    }
  }, [searchTerm]);

  const isWithinLast30Days = (date: any, count: any) => {
    const currentDate: any = new Date();
    const givenDate: any = new Date(date);
    const diffTime = Math.abs(currentDate - givenDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= parseInt(count);
  };

  const [selectedFilter, setSelectedFilter] = useState<FilterDataType>({
    Category: [],
    Date: [],
    Location: [],
  });
  const handleSelectFilter = () => {
    let tempHighArr = [...highlightData];
    Object.keys(selectedFilter).forEach((key) => {
      tempHighArr = tempHighArr.filter((item) => {
        if (key === "Category" && selectedFilter[key].length > 0)
          return (
            selectedFilter[key].includes(item.eventType) ||
            selectedFilter[key].includes(item.subtype!)
          );
        else if (key === "Location" && selectedFilter[key].length > 0)
          return (
            selectedFilter[key].find(
              ({ countryname }: any) => countryname === item.country
            ) ||
            selectedFilter[key].find(
              ({ regionname }: any) => regionname === item.region
            ) ||
            selectedFilter[key].find(
              ({ cityname }: any) => cityname === item.provience
            )
          );
        else if (key === "Date" && selectedFilter[key].length > 0) {
          return selectedFilter[key].includes("30") ||
            selectedFilter[key].includes("90")
            ? isWithinLast30Days(item.eventdate, selectedFilter[key][0])
            : item;
        } else return item;
      });
    });
    setHighlightSearchData(tempHighArr);
    setFilterAppliedFlag(true);
    setOpenFilterDrawer(false);
  };

  const handleClearAll = () => {
    setSelectedFilter({
      Category: [],
      Date: [],
      Location: [],
    });
    setLocationCountry("");
    setLocationRegion("");
    setLocationProvince("");
    setProvinceList([]);
    setRegionList([]);
    setHighlightSearchData(highlightData);
    setOpenFilterDrawer(false);
    setFilterAppliedFlag(false);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    //setSelectedFilter(newValue);
  };

  const handleSelect = (e: any) => {
    setLocationCountry(e.target.value);
    setLocationRegion("");
    setLocationProvince("");
    setProvinceList([]);
    let tempObj = { countryname: e.target.value };
    let tempCountryItem = locationFilter.find(
      ({ countryname }: any) => countryname === e.target.value
    );
    setSelectedFilter((prevData: any) => {
      return { ...prevData, Location: [tempObj] };
    });
    let temp = tempCountryItem.region.sort((a: any, b: any) =>
      a.regionname.localeCompare(b.regionname)
    );
    setRegionList(temp);
  };

  const handleSelectRegion = (e: any) => {
    setLocationRegion(e.target.value);
    setLocationProvince("");
    let tempObj = { regionname: e.target.value };
    let tempRegionItem = regionList.find(
      ({ regionname }: any) => regionname === e.target.value
    );
    setSelectedFilter((prevData: any) => {
      return { ...prevData, Location: [tempObj] };
    });
    let temp = tempRegionItem.city.sort((a: any, b: any) =>
      a.cityname.localeCompare(b.cityname)
    );
    setProvinceList(temp);
  };

  const handleSelectProvince = (e: any) => {
    setLocationProvince(e.target.value);
    let tempObj = { cityname: e.target.value };
    setSelectedFilter((prevData: any) => {
      return { ...prevData, Location: [tempObj] };
    });
  };

  const handleFilterChange = (value: any, name: any, secName: any) => {
    if (name === "radio") {
      setSelectedFilter((prevData: any) => {
        const updatedArray = [];
        updatedArray.push(value.value);
        return { ...prevData, [secName]: updatedArray };
      });
    } else {
      setSelectedFilter((prevData: any) => {
        // Create a copy of the specific array
        const updatedArray = [...prevData[secName]];
        // Check if the value is already present
        const valueIndex = updatedArray.indexOf(name);

        if (valueIndex === -1) {
          // If the value is not present, add it
          updatedArray.push(name);
        } else {
          // If the value is present, remove it
          updatedArray.splice(valueIndex, 1);
        }

        // Return the new state object with the updated array
        return { ...prevData, [secName]: updatedArray };
      });
    }
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header
          backLink={true}
          pageTitle="Highlights"
          filterFlag={filterAppliedFlag}
        ></Header>
        <button
          className={`filter-btn ${filterAppliedFlag ? "filter-applied" : ""}`}
          type="button"
          onClick={() => setOpenFilterDrawer(true)}
        >
          <i></i>
          {t("highlight_filterBtn")}
        </button>
      </header>
      <main>
        <div className="lyt-main typ-main">
          {/* <section>
            <div className="bs-section"></div>
          </section> */}
          <section>
            <div className="bs-section typ-depth">
              <div className="sec-cont">
                <div className="bs-form">
                  <div className="search-wrap">
                    <span className="search-icon"></span>
                    <input
                      type="text"
                      placeholder={t("highlight_searchPlaceholder")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button
                      className="close-btn"
                      type="button"
                      onClick={() => setSearchTerm("")}
                    >
                      <i></i>
                    </button>
                  </div>
                </div>
                <div className={`${styles.highlightList}`}>
                  {highlightSearchData.map((item, index) => {
                    return (
                      <div className={`${styles.item}`} key={index}>
                        <HighlightCard
                          {...item}
                          setTriggerGetHighlight={setTriggerGetHighlight}
                        ></HighlightCard>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
        <BottomSheet
          title={t("highlight_filterBtn")}
          primaryBtnText={t("highlight_applyBtn")}
          secondaryBtnText={t("highlight_clearAllBtn")}
          size="large"
          open={openFilterDrawer}
          setOpen={setOpenFilterDrawer}
          btnOnClick={handleSelectFilter}
          btnSecondaryOnClick={handleClearAll}
        >
          <div className="bs-filter">
            <div className="left-wrap">
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                {filterTabItems.map((item, index) => {
                  return <Tab key={index} label={item.title} />;
                })}
              </Tabs>
            </div>
            <div className="right-wrap">
              {filterTabItems.map((ele, index) => {
                return (
                  <div
                    className="tabpanel"
                    role="tabpanel"
                    hidden={value !== index}
                    key={index}
                  >
                    <div className="panel-list">
                      {ele.tabPanelItems.map((item: any, index: number) => {
                        return (
                          <div
                            className={`filter-item ${item.itemType}`}
                            key={index}
                          >
                            {item.itemType === "radio" ? (
                              <>
                                <div className="radio-group">
                                  <input
                                    type="radio"
                                    id={item.itemId}
                                    name={item.itemType}
                                    value={item.value}
                                    onChange={(e: any) => {
                                      handleFilterChange(
                                        e.target,
                                        e.target.name,
                                        ele.title
                                      );
                                    }}
                                    checked={selectedFilter[
                                      ele.title as keyof FilterDataType
                                    ].includes(item.value)}
                                  />
                                  <label htmlFor={item.itemId}>
                                    {item.label}
                                  </label>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {item.itemType === "checkbox" ? (
                              <>
                                <CheckBox
                                  id={item.itemId}
                                  label={item.label}
                                  name={item.itemId}
                                  value={item.value}
                                  onChange={(e: any) => {
                                    handleFilterChange(
                                      e.target,
                                      e.target.name,
                                      ele.title
                                    );
                                  }}
                                  isChecked={selectedFilter[
                                    ele.title as keyof FilterDataType
                                  ].includes(item.itemId)}
                                ></CheckBox>
                              </>
                            ) : (
                              ""
                            )}
                            {item.itemType === "location" ? (
                              <>
                                <div className="filter-sub-section typ-location">
                                  <h3 className="sub-sec-title">
                                    Select your location
                                  </h3>
                                  <div className="bs-form">
                                    <form>
                                      <div className="form-body">
                                        <div className="form-group">
                                          <div className="bs-select">
                                            <FormControl fullWidth>
                                              <InputLabel className="input-label">
                                                Country
                                              </InputLabel>
                                              <Select
                                                id="country"
                                                value={locationCountry}
                                                label={"Country"}
                                                onChange={handleSelect}
                                                MenuProps={{
                                                  style: {
                                                    maxHeight: 250,
                                                  },
                                                }}
                                              >
                                                {locationFilter.map(
                                                  (
                                                    item: any,
                                                    index: number
                                                  ) => (
                                                    <MenuItem
                                                      key={index}
                                                      value={item.countryname}
                                                    >
                                                      {item.countryname}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          </div>
                                        </div>
                                        {regionList.length > 0 ? (
                                          <div className="form-group">
                                            <div className="bs-select">
                                              <FormControl fullWidth>
                                                <InputLabel className="input-label">
                                                  Region
                                                </InputLabel>
                                                <Select
                                                  id="region"
                                                  value={locationRegion}
                                                  label={"Region"}
                                                  onChange={handleSelectRegion}
                                                  MenuProps={{
                                                    style: {
                                                      maxHeight: 250,
                                                    },
                                                  }}
                                                >
                                                  {regionList.map(
                                                    (
                                                      item: any,
                                                      index: number
                                                    ) => (
                                                      <MenuItem
                                                        key={index}
                                                        value={item.regionname}
                                                      >
                                                        {item.regionname}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>
                                              </FormControl>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {provinceList.length > 0 &&
                                        regionList.length > 0 ? (
                                          <div className="form-group">
                                            <div className="bs-select">
                                              <FormControl fullWidth>
                                                <InputLabel className="input-label">
                                                  Provinces / Cities
                                                </InputLabel>
                                                <Select
                                                  id="province"
                                                  value={locationProvince}
                                                  label={"Provinces / Cities"}
                                                  onChange={
                                                    handleSelectProvince
                                                  }
                                                  MenuProps={{
                                                    style: {
                                                      maxHeight: 250,
                                                    },
                                                  }}
                                                >
                                                  {provinceList.map(
                                                    (
                                                      item: any,
                                                      index: number
                                                    ) => (
                                                      <MenuItem
                                                        key={index}
                                                        value={item.cityname}
                                                      >
                                                        {item.cityname}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>
                                              </FormControl>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {/* {item.count !== 0 ? (
                              <span className="count">{item.count}</span>
                            ) : (
                              <></>
                            )} */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </BottomSheet>
      </main>
    </>
  );
}

export default HighlightListing;
