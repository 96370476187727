import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useEffect, useState } from "react";
import introPageData from "../../../../data/activationSteps.json";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface documentReqType {
  iconClass: string;
  label: string;
  documentsList: string[];
}

export interface documentDetailsType {
  title: string;
  documentReq: documentReqType[];
}

export interface SignUpIntroPageDataType {
  activationSteps: string[];
  documentDetailsSec: documentDetailsType[];
}

function SignupIntro() {
  const { i18n, t } = useTranslation(["SignupIntro"]);
  const lang = getSessionKey("selectedLangCode");
  const [openDocumentsDrawer, setOpenDocumentsDrawer] = useState(false);
  const [openDrawerDocList, setOpenDrawerDocList] = useState<string[]>([]);
  const [drawerLabel, setDrawerLabel] = useState<string>("");
  const [pageIntroData, setPageIntroData] = useState<SignUpIntroPageDataType>();
  const selLang = getSessionKey("selectedLangCode");
  const userRole = getSessionKey("userRole");
  const typedintroPageData = introPageData as Record<
    "en" | "it",
    { [key: string]: any }
  >;
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  useEffect(() => {
    setPageIntroData(
      typedintroPageData[selLang as keyof typeof introPageData][
        userRole as keyof typeof introPageData
      ]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-signup">
          <h1 className={`${styles.title}`}>{t("signupintro_pgTitle")}</h1>
          <div className={`${styles.section}`}>
            <h2 className={`${styles.secTitle}`}>
              {t("signupintro_pgSubTitle")}{" "}
              {pageIntroData?.activationSteps.length} {t("signupintro_steps")}
            </h2>
            <div className={`${styles.cardWrap}`}>
              <div className={`${styles.card}`}>
                <div className={`${styles.stepsList}`}>
                  {pageIntroData?.activationSteps.map(
                    (item: string, index: number) => {
                      return (
                        <div className={`${styles.steps}`} key={index}>
                          <p>{item}</p>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.section}`}>
            <h2 className={`${styles.secTitle}`}>
              {t("signupintro_mandotaryDocuments")}
            </h2>
            <div className={`${styles.cardWrap}`}>
              {pageIntroData?.documentDetailsSec.map((item, index: number) => {
                return (
                  <>
                    {item.title !== "" && (
                      <h3 className={`${styles.cardTitle}`}>{item.title}</h3>
                    )}
                    <div className={`${styles.card}`} key={index}>
                      <div className={`${styles.docList}`}>
                        {item?.documentReq.map((ele, index: number) => {
                          return (
                            <div
                              className={`${styles.iconTextWrap}`}
                              key={index}
                            >
                              <i className={`${styles[ele.iconClass]}`}></i>
                              <span>{ele.label}</span>
                              {ele.documentsList.length > 0 && (
                                <Button
                                  btnStyle="text"
                                  onClick={() => {
                                    setDrawerLabel(ele.label);
                                    setOpenDrawerDocList(ele.documentsList);
                                    setOpenDocumentsDrawer(true);
                                  }}
                                >
                                  {t("signupintro_documentsViewBtn")}
                                </Button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="form-foot">
            <Button
              navigation={true}
              navigationUrl={`${
                userRole === "legalrepresent"
                  ? "/signup/details-form/company-details"
                  : "/signup/details-form"
              }`}
              btnStyle="primary"
              type="submit"
            >
              {t("signupintro_proceedBtn")}
            </Button>
          </div>
        </div>
      </main>
      <BottomSheet
        title={t("signupintro_drawerTitle")}
        subText={`${t("signupintro_drawerIdentificationTitle")}.`}
        primaryBtnText={t("signupintro_drawerBtn")}
        size="medium"
        open={openDocumentsDrawer}
        setOpen={setOpenDocumentsDrawer}
        btnOnClick={() => {
          setOpenDocumentsDrawer(false);
        }}
      >
        <div className={`${styles.drawerWrap}`}>
          <p className={`${styles.cardDesc}`}>
            {t("signupintro_drawerAttachTitle")}
          </p>
          <div className={`${styles.cardCont}`}>
            <ul className={`${styles.list}`}>
              {openDrawerDocList.map((item: string, index: number) => {
                return (
                  <li className={`${styles.item}`} key={index}>
                    <span className={`${styles.circle}`}></span>
                    <span className={`${styles.text}`}>{item}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          {(drawerLabel === "Residence Proof" || "Verifica residenza") && (
            <div className={`${styles.content}`}>
              <i className={`${styles.error}`}></i>
              <span>{t("signupintro_drawerWarningMsg")}</span>
            </div>
          )}
        </div>
      </BottomSheet>
    </>
  );
}

export default SignupIntro;
