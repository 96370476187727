import { useState, useEffect } from "react";
import NewsCard, { NewsCardData } from "src/components/news-card/NewsCard";
import styles from "./style.module.scss";
import Header from "src/components/common/header/Header";
import axios from "axios";
import { authToken, headers } from "src/helper/authToken";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

function NewsListing() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Header"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const [newsCards, setNewsCards] = useState<NewsCardData[]>([]);
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  useEffect(() => {
    // Define your header
    setLoading(true);
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/news?lang_id=${
          getSessionKey("userData")?.lang_id || getSessionKey("selectedLang")
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setNewsCards(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);
  /* const [newsCards, setNewsCards] = useState<NewsCardData[]>([
    {
      newsID: "001",
      title: "CAGL to acquire Madura Micro Finance for ₹876 cr",
      Newdate: "04th November, 2023",
      chip: "Leading",
      imgUrl:
        "https://fastly.picsum.photos/id/20/3670/2462.jpg?hmac=CmQ0ln-k5ZqkdtLvVO23LjVAEabZQx2wOaT4pyeG10I",
        addhome:true
    },
    {
      newsID: "002",
      title: "Creditas raises pre-series A funding from Crowd",
      Newdate: "19th October, 2023",
      chip: "Trending",
      imgUrl:
        "https://fastly.picsum.photos/id/24/4855/1803.jpg?hmac=ICVhP1pUXDLXaTkgwDJinSUS59UWalMxf4SOIWb9Ui4",
        addhome:true
    },
    {
      newsID: "003",
      title:
        "Startup by 2 Friends Has Turned Plastic Waste Into 300 Million FMCG Bottles",
      Newdate: "11th October, 2023",
      chip: "Highlight",
      imgUrl:
        "https://fastly.picsum.photos/id/26/4209/2769.jpg?hmac=vcInmowFvPCyKGtV7Vfh7zWcA_Z0kStrPDW3ppP0iGI",
        addhome:true
    },
    {
      newsID: "004",
      title:
        "Startup by 2 Friends Has Turned Plastic Waste Into 300 Million FMCG Bottles",
      Newdate: "11th October, 2023",
      chip: "In Focus",
      imgUrl:
        "https://fastly.picsum.photos/id/48/5000/3333.jpg?hmac=y3_1VDNbhii0vM_FN6wxMlvK27vFefflbUSH06z98so",
        addhome:true
    },
  ]); */
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <header>
            <Header
              backLink={true}
              pageTitle={t("header_latestNewsTitle")}
            ></Header>
          </header>
          <main>
            <div className="lyt-main typ-main">
              <section>
                <div className="bs-section typ-news typ-depth">
                  <div className="sec-cont">
                    <div className={`${styles.newsList}`}>
                      {newsCards.map((item, index) => {
                        return (
                          <div key={index} className={`${styles.item}`}>
                            <NewsCard {...item}></NewsCard>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </>
      )}
    </>
  );
}
export default NewsListing;
